import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';
import BootstrapVue from 'bootstrap-vue'
import { mapState, mapMutations } from "vuex";

import App from './App';

Vue.config.productionTip = false;

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import "@/themes/site.scss";

import api from "./api";
import store from "./store";
import router from "./router";
import { Translate, vuePhoneNumberInputTranslations } from "@/lib/translator"

Vue.prototype.$api = api;

Vue.filter('formatDate', function(value) {
  if (!value) { return '(n/a)'; }
  let d = new Date(value);
  return d.getFullYear() + '-' + ((d.getMonth() < 9) ? '0' : '') + (d.getMonth() + 1) + '-' +
    ((d.getDate() < 10) ? '0' : '') + d.getDate();
});

Vue.filter('defaultValue', function(value, def) {
  if (!value) { return def; }
  return value;
});

Vue.use(VueCompositionAPI);
Vue.use(BootstrapVue);

Vue.component('font-awesome-icon', FontAwesomeIcon);

new Vue({
  render: h => h(App),
  store,
  router,
}).$mount('#app');


Vue.mixin({
  computed: {
    ...mapState('language', ['selectedLanguage']),
  },
  methods: {
    T: function(key) {
      return Translate(key, this.selectedLanguage);
    },
    Language: function() {
      return this.selectedLanguage;
    },
    phoneNumberInputTranslations: function() {
      return vuePhoneNumberInputTranslations(this.selectedLanguage);
    },
    msgBoxYesNo: function(title) {
            return this.$bvModal.msgBoxConfirm(title, {
              centered: true,
              contentClass: "yes-no-dialog",
              footerClass: "yes-no-footer",
              hideHeader: true,
              okTitle: 'Yes',
              cancelTitle: 'No',
              buttonSize: 'lg'
      });
    },
    ...mapMutations('language', ['setLanguage']),
  }
});

import { mixinNotificationDialog, mixinConfirmationDialog } from '@/lib/dialog';
Vue.mixin(mixinNotificationDialog);
Vue.mixin(mixinConfirmationDialog);
